import {
  hasExceededCharacterLimit,
  resetCharactersRemainingCounts,
  updateInputRemainingCharacters
} from './behaviors/characters-remaining'
import {onFocus, onInput, onKey} from './onfocus'
import type {EmojiPickerElement} from './emoji-picker-element'
import type IncludeFragmentElement from '@github/include-fragment-element'
import {eventToHotkeyString} from '@github/hotkey'
import {on} from 'delegated-events'
import {requestSubmit} from './form'

export function isEditMode(container: Element): boolean {
  const detailsEl = container.querySelector<HTMLElement>('.js-user-status-details')!
  return detailsEl.hasAttribute('open')
}

function loadOrgPicker(container: Element) {
  const loader = container.querySelector<IncludeFragmentElement>('.js-user-status-org-picker')
  if (loader && !loader.src) {
    loader.src = loader.getAttribute('data-url')!
  }
}

function loadEmojiPicker(container: Element, showPicker: boolean) {
  const loader = container.querySelector<IncludeFragmentElement>('.js-user-status-emoji-picker')
  if (!loader || loader.src) return

  let url = loader.getAttribute('data-url')!
  if (showPicker) {
    const urlObj = new URL(url, window.location.origin)
    urlObj.searchParams.append('show_picker', '1')
    url = urlObj.toString()
  }
  loader.src = url
}

function resetEmojiPicker(container: HTMLElement) {
  const picker = container.querySelector<EmojiPickerElement>('.js-emoji-picker')
  if (picker) {
    picker.reset()
    picker.close()
  }
}

function resetSelectedEmoji(container: Element) {
  const originalEmojiDisplay = container.querySelector<HTMLElement>('.js-user-status-original-emoji')!
  const selectedEmojiDisplay = container.querySelector<HTMLElement>('.js-user-status-custom-emoji')!
  const noEmojiIcon = container.querySelector<HTMLElement>('.js-user-status-no-emoji-icon')!
  selectedEmojiDisplay.innerHTML = originalEmojiDisplay.innerHTML
  noEmojiIcon.hidden = selectedEmojiDisplay.hasChildNodes()
}

function closeUserStatusEdit(container: HTMLElement) {
  const messageField = container.querySelector<HTMLInputElement>('.js-user-status-message-field')!
  resetSelectedEmoji(container)
  resetEmojiPicker(container)
  messageField.value = messageField.defaultValue
  resetCharactersRemainingCounts(container)
}

function openUserStatusEdit(container: Element, showPicker: boolean) {
  resetSelectedEmoji(container)
  loadEmojiPicker(container, showPicker)
  loadOrgPicker(container)
}

function hideSuggestedUserStatusesIfNecessary(container: Element) {
  const suggestionsContainer = container.querySelector<HTMLElement>('.js-user-status-suggestions')!
  const messageField = container.querySelector<HTMLInputElement>('.js-user-status-message-field')!
  const message = messageField.value.trim()
  const messageExists = message.length > 0

  suggestionsContainer.classList.toggle('collapsed', messageExists)
  suggestionsContainer.setAttribute('aria-expanded', messageExists.toString())
}

function toggleUserStatusEdit(container: HTMLElement, showPicker: boolean) {
  if (isEditMode(container)) {
    closeUserStatusEdit(container)
  } else {
    openUserStatusEdit(container, showPicker)
  }

  disableUserStatusSubmitIfNecessary(container)
}

function displayEmoji(container: Element, emojiEl: Element) {
  const emojiDisplay = container.querySelector<HTMLElement>('.js-user-status-custom-emoji')!
  emojiDisplay.innerHTML = emojiEl.innerHTML

  const noEmojiIcon = container.querySelector<HTMLElement>('.js-user-status-no-emoji-icon')!
  noEmojiIcon.hidden = true
}

function setEmojiField(container: Element, emojiButton: HTMLButtonElement) {
  const emojiField = container.querySelector<HTMLInputElement>('.js-user-status-emoji-field')!
  emojiField.value = emojiButton.value
}

function selectEmoji(emojiButton: HTMLButtonElement) {
  const container = emojiButton.closest<HTMLElement>('.js-user-status-container')!
  const picker = container.querySelector<EmojiPickerElement>('.js-emoji-picker')!

  setEmojiField(container, emojiButton)
  picker.querySelector('.selected-emoji')?.classList.remove('selected-emoji')
  emojiButton.classList.add('selected-emoji')
  displayEmoji(container, emojiButton)
  disableUserStatusSubmitIfNecessary(container)
  picker.close()
}

function handleUserStatusEscape(input: HTMLInputElement, event: KeyboardEvent) {
  if (eventToHotkeyString(event) === 'Escape') {
    const container = input.closest('.js-user-status-container')
    if (!(container instanceof HTMLElement)) return

    input.value = input.defaultValue
    toggleUserStatusEdit(container, false)
  }
}

function disableUserStatusSubmitIfNecessary(container: Element) {
  const submitButton = container.querySelector<HTMLButtonElement>('.js-user-status-submit')!
  const clearButton = container.querySelector<HTMLButtonElement>('.js-clear-user-status-button')!
  const messageField = container.querySelector<HTMLInputElement>('.js-user-status-message-field')!
  const emojiField = container.querySelector<HTMLInputElement>('.js-user-status-emoji-field')!
  const message = messageField.value.trim()
  const emoji = emojiField.value.trim()
  let submitDisabled = false
  let clearDisabled = false

  if (hasExceededCharacterLimit(messageField)) {
    submitDisabled = true
  }

  if (message.length < 1 && emoji.length < 1) {
    submitDisabled = true

    // Don't disable the clear button even when the message field is wiped if there's
    // already a saved status the user wants to clear
    if (!clearButton.classList.contains('js-user-status-exists')) {
      clearDisabled = true
    }
  }

  submitButton.disabled = submitDisabled
  clearButton.disabled = clearDisabled
}

function clearUserStatus(container: HTMLElement) {
  // Hide the container first so we don't see flickering state changes
  container.hidden = true

  const emojiField = container.querySelector<HTMLInputElement>('.js-user-status-emoji-field')!
  emojiField.value = ''

  const noEmojiIcon = container.querySelector<HTMLElement>('.js-user-status-no-emoji-icon')!
  noEmojiIcon.hidden = false

  const customEmojiDisplay = container.querySelector<HTMLElement>('.js-user-status-custom-emoji')!
  customEmojiDisplay.innerHTML = ''

  const messageField = container.querySelector<HTMLInputElement>('.js-user-status-message-field')!
  messageField.value = ''
  if (messageField.classList.contains('js-characters-remaining-field')) {
    updateInputRemainingCharacters(messageField)
  }

  const selectedEmojiButton = container.querySelector('.selected-emoji.js-user-status-emoji-button')
  if (selectedEmojiButton) {
    selectedEmojiButton.classList.remove('selected-emoji')
  }

  const limitedAvailabilityCheckbox = container.querySelector<HTMLInputElement>(
    '.js-user-status-limited-availability-checkbox'
  )!
  limitedAvailabilityCheckbox.checked = false

  const expiresAtInput = container.querySelector('.js-user-status-expiration-date-input')

  if (expiresAtInput && expiresAtInput instanceof HTMLInputElement) {
    expiresAtInput.value = ''
  }

  disableUserStatusSubmitIfNecessary(container)
  hideSuggestedUserStatusesIfNecessary(container)

  const form = container.querySelector<HTMLFormElement>('.js-user-status-form')!
  requestSubmit(form)
}

function selectOrganization(button: HTMLButtonElement) {
  const container = button.closest<HTMLElement>('.js-user-status-container')!
  const orgIDField = container.querySelector<HTMLInputElement>('.js-user-status-org-id-field')!
  const orgDetails = container.querySelector<HTMLElement>('.js-user-status-org-details')!
  const orgEl = container.querySelector<HTMLElement>('.js-user-status-selected-org')!
  const orgDisplay = button.querySelector<HTMLElement>('.js-user-status-org-display')!
  const orgMessageEl = container.querySelector<HTMLElement>('.js-user-status-org-message')!
  const orgID = button.value
  const anyOrgSelected = orgID !== ''
  const messageField = container.querySelector<HTMLInputElement>('.js-user-status-message-field')!
  const previouslySelectedButton = container.querySelector('.js-user-status-org-button.selected')
  let suggesterUrl = ''

  if (previouslySelectedButton) {
    previouslySelectedButton.classList.remove('selected')
  }
  button.classList.add('selected')

  orgIDField.value = orgID
  orgEl.innerHTML = ''
  const orgDisplayCopy = orgDisplay.cloneNode(true)
  if (orgDisplayCopy instanceof HTMLElement) {
    orgDisplayCopy.hidden = false
  }
  orgEl.appendChild(orgDisplayCopy)
  orgDetails.removeAttribute('open')

  if (anyOrgSelected) {
    const prefix = orgMessageEl.getAttribute('data-prefix')!
    const suffix = orgMessageEl.getAttribute('data-suffix')!
    const orgLogin = button.getAttribute('data-org')!

    orgMessageEl.textContent = `${prefix}${orgLogin}${suffix}`
    orgMessageEl.hidden = false

    const suggesterUrlWithOrg = new URL(messageField.getAttribute('data-org-url')!, window.location.origin)
    const params = new URLSearchParams(suggesterUrlWithOrg.search.slice(1))
    params.append('global_id', orgID)
    suggesterUrlWithOrg.search = params.toString()
    suggesterUrl = suggesterUrlWithOrg.toString()
  } else {
    orgMessageEl.textContent = orgMessageEl.getAttribute('data-none')!
    orgMessageEl.hidden = true

    suggesterUrl = messageField.getAttribute('data-no-org-url')!
  }

  messageField.closest<HTMLElement>('text-expander')!.setAttribute('data-mention-url', suggesterUrl)
}

function selectedSuggestedUserStatus(button: HTMLButtonElement) {
  const container = button.closest<HTMLElement>('.js-user-status-container')!
  const emojiEl = button.querySelector<HTMLElement>('.js-predefined-user-status-emoji')!
  const messageEl = button.querySelector<HTMLElement>('.js-predefined-user-status-message')!
  const messageField = container.querySelector<HTMLInputElement>('.js-user-status-message-field')!

  messageField.value = (messageEl.textContent || '').trim()
  displayEmoji(container, emojiEl)
  setEmojiField(container, button)
  disableUserStatusSubmitIfNecessary(container)
  hideSuggestedUserStatusesIfNecessary(container)

  if (messageField.classList.contains('js-characters-remaining-field')) {
    updateInputRemainingCharacters(messageField)
  }
}

function setExpirationInterval(container: HTMLElement, button: HTMLButtonElement) {
  const expirationButton = container.querySelector<HTMLElement>('.js-user-status-expiration-interval-selected')!
  const timeDropdown = container.querySelector<HTMLElement>('.js-user-status-expire-drop-down')!
  const expiresAtInput = container.querySelector<HTMLInputElement>('.js-user-status-expiration-date-input')!

  expirationButton.textContent = button.title
  expiresAtInput.value = button.value
  timeDropdown.removeAttribute('open')
}

function getUserStatusContainer(el: Element): HTMLElement {
  return el.closest<HTMLElement>('.js-user-status-container')!
}

on('click', '.js-user-status-expire-button', function (event) {
  const button = event.currentTarget
  if (!(button instanceof HTMLButtonElement)) return
  const container = getUserStatusContainer(button)

  setExpirationInterval(container, button)
})

on('click', '.js-toggle-user-status-edit', function (event) {
  const target = event.currentTarget as Element
  const container = getUserStatusContainer(target)
  const showPicker = !!target.closest('.js-toggle-user-status-emoji-picker')
  toggleUserStatusEdit(container, showPicker)
})

export function init(container: HTMLElement) {
  loadEmojiPicker(container, false)
  loadOrgPicker(container)
}

on(
  'click',
  '.js-toggle-user-status-emoji-picker',
  function (event) {
    const container = getUserStatusContainer(event.currentTarget as Element)
    const picker = container.querySelector<EmojiPickerElement>('.js-emoji-picker')
    if (!picker) return
    event.stopPropagation()
    if (picker.hidden) {
      picker.open()
    } else {
      picker.close()
    }
  },
  {capture: true}
)

on('click', '.js-user-status-emoji-button', function (event) {
  const button = event.currentTarget
  if (!(button instanceof HTMLButtonElement)) return

  selectEmoji(button)
})

on('click', '.js-emoji-tab', function (event) {
  const button = event.currentTarget
  if (!(button instanceof HTMLElement)) return

  const picker = button.closest<EmojiPickerElement>('.js-emoji-picker')!
  const filterInput = picker.querySelector<HTMLInputElement>('.js-emoji-picker-filter')

  picker.clear()

  if (!picker.hidden && filterInput) {
    filterInput.focus()
  }
})

onFocus('.js-user-status-message-field', function (input) {
  const container = getUserStatusContainer(input)
  hideSuggestedUserStatusesIfNecessary(container)
})

onKey('keyup', '.js-user-status-message-field', (event: KeyboardEvent) => {
  const input = event.target
  if (!(input instanceof HTMLInputElement)) return
  handleUserStatusEscape(input, event)
})

onKey('keyup', '.js-emoji-picker-filter', (event: KeyboardEvent) => {
  const input = event.target
  if (!(input instanceof HTMLInputElement)) return
  handleUserStatusEscape(input, event)
})

on('click', '.js-clear-user-status-button', function (event) {
  const button = event.currentTarget
  if (!(button instanceof HTMLButtonElement)) return
  const container = button.closest<HTMLElement>('.js-user-status-container')!

  clearUserStatus(container)
})

on('click', '.js-predefined-user-status', function (event) {
  const button = event.currentTarget
  if (!(button instanceof HTMLButtonElement)) return

  selectedSuggestedUserStatus(button)
})

onInput('.js-user-status-message-field', function (event) {
  const input = event.target
  if (!(input instanceof HTMLElement)) return
  const form = input.closest<HTMLElement>('.js-user-status-form')!

  disableUserStatusSubmitIfNecessary(form)
  hideSuggestedUserStatusesIfNecessary(form)
})

on('click', '.js-user-status-org-button', function (event) {
  const button = event.currentTarget
  if (!(button instanceof HTMLButtonElement)) return

  selectOrganization(button)
})

on('change', '.js-user-status-limited-availability-checkbox', function (event) {
  const busyCheckbox = event.target
  if (!(busyCheckbox instanceof HTMLInputElement)) return

  const form = busyCheckbox.closest<HTMLElement>('.js-user-status-form')!
  const isBusy = busyCheckbox.checked
  const defaultMessage = busyCheckbox.getAttribute('data-default-message')!
  const messageField = form.querySelector<HTMLInputElement>('.js-user-status-message-field')!

  if (isBusy && messageField.value.length < 1) {
    messageField.value = defaultMessage
  } else if (!isBusy && messageField.value === defaultMessage) {
    messageField.value = ''
  }

  disableUserStatusSubmitIfNecessary(form)
  hideSuggestedUserStatusesIfNecessary(form)
})
